
import { defineComponent } from 'vue';
import AutoComplete from 'primevue/autocomplete';
import { FilterAction, FilterAdditional, FilterDestinations, FilterOrigins } from '@/typings/enums';
import { FilterLabels } from '@/constants';
import { SearchResult } from '@/services/model/vista';

export default defineComponent({
  name: 'GlobalSearch',
  components: {
    AutoComplete
  },
  data() {
    return {
      search: null,
      searchResults: [] as SearchResult[],
      searching: false,
      FilterLabels: FilterLabels
    };
  },
  computed: {
    // returns the current filter object
    storedSearches(): SearchResult[] {
      return this.$store.state.storedSearches;
    }
  },
  methods: {
    toSearchResult(slotProps: any): { item: SearchResult } {
      // this is a hacky workaround, which is needed because we currently cannot use typescript in the template section.
      // tests will fail when using typescript in template section with out current combination of jest@24.9.0 and vue-jest@5.0.0
      return slotProps as { item: SearchResult };
    },
    fetchSearchResults(search: string) {
      //if search is empty, put stored searches in the search results
      if (!search) {
        this.searchResults = this.storedSearches;
        return;
      }

      this.searching = true;
      this.$store.dispatch('SEARCH', search).then((response) => {
        this.searching = false;
        if (response && response.length > 0) {
          this.searchResults = response;
        } else {
          this.searchResults = [{ label: 'No results found' }];
        }
      });
    },
    fetchSearch(data: any) {
      // figure out to what category the filter belongs to
      let section: string | null = null;
      if (Object.values(FilterAdditional).find((f) => f === data.value.fieldName)) {
        section = 'additionalFields';
      } else if (Object.values(FilterOrigins).find((f) => f === data.value.fieldName)) {
        section = 'origin';
      } else if (Object.values(FilterDestinations).find((f) => f === data.value.fieldName)) {
        section = 'destination';
      }

      const label = data.value.label ? data.value.label : data.value.id;
      let params: any;
      // if section has multiple options, the object is formatted differently
      // note: the whole data model should be changed, this is a consequence of developing a frontend without a backend, now we have to deal with this mess or redo everything.
      if (section) {
        const optionLabel = this.FilterLabels[data.value.fieldName];
        params = {
          id: section,
          option: { detail: optionLabel, id: data.value.id, name: label },
          title: optionLabel,
          action: FilterAction.ADD,
          selectedBox: { id: data.value.fieldName, name: optionLabel }
        };
      } else {
        params = {
          id: data.value.fieldName,
          option: { detail: '', id: data.value.id, name: label },
          title: label,
          action: FilterAction.ADD
        };
      }

      // commit the search to the store
      this.$store.commit('setStoredSearches', data.value);

      this.$emit('resetFilter');
      this.$emit('updateFilter', params);
      this.search = null;
    },
    // generates a detail text for global search entries
    getTypeTag(fieldName: string | undefined): string {
      if (fieldName) {
        let tag = '';
        if (Object.values(FilterOrigins).find((f) => f === fieldName)) {
          tag = ' - Origin Plant';
        } else if (Object.values(FilterDestinations).find((f) => f === fieldName)) {
          tag = ' - Ship To Destination';
        }
        return FilterLabels[fieldName] + tag;
      }
      return '';
    }
  },
  mounted() {
    this.searchResults = this.storedSearches;
  }
});
