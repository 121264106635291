
import { defineComponent } from 'vue';
import { TimelineEventLongTexts, TimelineEventShortTexts, TimelineSubEventTexts } from '@/typings/enums';
import { globalDateTimeFormat } from '@/constants';
import { ShipmentStageSegmentLine, ShipmentStageSegmentLineTypeEnum } from '@/services/model/vista';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Filters from '@/scripts/componentFilter';

dayjs.extend(utc);
dayjs.extend(timezone);

export default defineComponent({
  name: 'TimelineTable',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dayjs: dayjs,
      globalDateTimeFormat: globalDateTimeFormat
    };
  },
  methods: {
    displayDescription(event: ShipmentStageSegmentLine) {
      const type = event.type;
      const subType = event.subType;
      const location = event.location;

      if (!type) {
        return 'not defined';
      }

      if (type === 'OTHER' && subType) {
        return TimelineSubEventTexts[subType];
      }

      let description = TimelineEventShortTexts[type] || '';

      if (subType) {
        description += ' - ' + TimelineSubEventTexts[subType];
      } else if (TimelineEventLongTexts[type]) {
        description += ' - ' + TimelineEventLongTexts[type];
      }

      if (location) {
        description += ' (' + location + ')';
      }

      return description;
    },
    displayEventTimestamp(event: ShipmentStageSegmentLine) {
      if (event.date) {
        return this.dateTzAndFormat(event.date);
      } else if (event.type === ShipmentStageSegmentLineTypeEnum.TechnicalClosed) {
        return '';
      } else {
        return 'Not found';
      }
    },
    dateTzAndFormat(date: string) {
      return Filters.toLocalDate(date, true);
    },
    orderSubTimeline(arr) {
      const arrWithIndex = arr.map((item, index) => ({ ...item, index }));
      return arrWithIndex.sort((a, b) => {
        const timeDiff = new Date(b.eventTransmissionTime).getTime() - new Date(a.eventTransmissionTime).getTime();
        // If eventTransmissionTime is the same, sort by the original index of the elements; otherwise, sort by eventTransmissionTime
        return timeDiff !== 0 ? timeDiff : b.index - a.index;
      });
    },
    getSubTimeline(subTimeline: Array<ShipmentStageSegmentLine>) {
      const sortedSubTimeline = this.orderSubTimeline(subTimeline).reverse();
      const filteredTimeline = sortedSubTimeline.filter((detail, index) => {
        detail.originalIndex = index;
        return this.shouldDisplayRow(sortedSubTimeline, detail);
      });

      let currentIndex = 0;
      while (currentIndex < filteredTimeline.length - 1) {
        const currentDetail = filteredTimeline[currentIndex];
        if (currentDetail.hideNextLines) {
          filteredTimeline.splice(currentIndex + 1, 0, {
            originalIndex: currentDetail.originalIndex,
            expandButton: true
          });
          currentIndex++;
        }
        currentIndex++;
      }

      return filteredTimeline.reverse();
    },
    shouldDisplayRow(subTimeline, detail) {
      if (detail.expanded === true) {
        return true;
      }
      const index = detail.originalIndex;
      const currentType = detail.type;
      const currentSubType = detail.subType;
      let consecutiveCount = 1;

      // Check future rows
      for (let i = index + 1; i < subTimeline.length; i++) {
        if (subTimeline[i].type === currentType && subTimeline[i].subType === currentSubType) {
          consecutiveCount++;
        } else {
          break;
        }
      }

      // Hide rows if there are more than 4 consecutive rows with the same type
      if (consecutiveCount > 3) {
        if (this.isFirstOfConsecutive(subTimeline, detail) && detail.hideNextLines !== false) {
          detail.hideNextLines = true;
          return true;
        } else if (detail.hideNextLines === false) {
          return true;
        }
        return false;
      }

      return true;
    },
    isFirstOfConsecutive(subTimeline, detail) {
      const index = detail.originalIndex;
      if (index - 1 >= 0 && subTimeline[index - 1] !== undefined) {
        return subTimeline[index - 1].type !== detail.type && subTimeline[index - 1].subType !== detail.subType;
      }

      return true;
    },
    expandRows(subTimeline, index) {
      if (subTimeline[index] !== undefined) {
        subTimeline[index].hideNextLines = false;
        const currentType = subTimeline[index].type;
        const currentSubType = subTimeline[index].subType;
        for (let i = index + 1; i < subTimeline.length; i++) {
          if (subTimeline[i].type === currentType && subTimeline[i].subType == currentSubType) {
            subTimeline[i].expanded = true;
          } else {
            break;
          }
        }
      }
    }
  }
});
