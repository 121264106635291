/* tslint:disable */
/* eslint-disable */
/**
 * Vista API
 * Open API definition
 *
 * The version of the OpenAPI document: 1.20.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { Point } from './point';
// May contain unused imports in some cases
// @ts-ignore
import { SegmentLineDate } from './segment-line-date';
// May contain unused imports in some cases
// @ts-ignore
import { ShipmentStageSegmentLine } from './shipment-stage-segment-line';

/**
 * Shipment segments
 * @export
 * @interface SegmentLine
 */
export interface SegmentLine {
    /**
     * Shipping type
     * @type {string}
     * @memberof SegmentLine
     */
    'shippingType'?: SegmentLineShippingTypeEnum;
    /**
     * 
     * @type {Point}
     * @memberof SegmentLine
     */
    'point'?: Point;
    /**
     * Stage classifier
     * @type {string}
     * @memberof SegmentLine
     */
    'segmentClassifier'?: SegmentLineSegmentClassifierEnum;
    /**
     * 
     * @type {SegmentLineDate}
     * @memberof SegmentLine
     */
    'estimatedArrival'?: SegmentLineDate;
    /**
     * 
     * @type {SegmentLineDate}
     * @memberof SegmentLine
     */
    'actualArrival'?: SegmentLineDate;
    /**
     * 
     * @type {SegmentLineDate}
     * @memberof SegmentLine
     */
    'estimatedDeparture'?: SegmentLineDate;
    /**
     * 
     * @type {SegmentLineDate}
     * @memberof SegmentLine
     */
    'actualDeparture'?: SegmentLineDate;
    /**
     * Partner info
     * @type {string}
     * @memberof SegmentLine
     */
    'partnerName'?: string;
    /**
     * Timeline associated with each stage
     * @type {Array<ShipmentStageSegmentLine>}
     * @memberof SegmentLine
     */
    'subTimeline'?: Array<ShipmentStageSegmentLine>;
}

export const SegmentLineShippingTypeEnum = {
    Truck: 'TRUCK',
    Mail: 'MAIL',
    Train: 'TRAIN',
    Sea: 'SEA',
    Plane: 'PLANE',
    Pipeline: 'PIPELINE',
    Other: 'OTHER'
} as const;

export type SegmentLineShippingTypeEnum = typeof SegmentLineShippingTypeEnum[keyof typeof SegmentLineShippingTypeEnum];
export const SegmentLineSegmentClassifierEnum = {
    Main: 'MAIN',
    Intermediate: 'INTERMEDIATE'
} as const;

export type SegmentLineSegmentClassifierEnum = typeof SegmentLineSegmentClassifierEnum[keyof typeof SegmentLineSegmentClassifierEnum];


