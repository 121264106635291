
import { defineComponent } from 'vue';

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';

import { Filter, FilterItem, SavedFilter } from '@/typings';
import { FilterType, FilterAction } from '@/typings/enums';

import FilterListItem from '@/components/main/FilterListItem.vue';
import { AutoCompleteEndpoints, Conditions } from '@/constants';

import dayjs from 'dayjs';

export default defineComponent({
  name: 'Filters',
  components: {
    TabView,
    TabPanel,
    Checkbox,
    Calendar,
    AutoComplete,
    FilterListItem
  },
  props: {
    savedFilters: {
      type: Array,
      default: () => [] as SavedFilter[]
    },
    selectedConditions: {
      type: Object,
      default: () => ({})
    },
    canSave: {
      type: Boolean,
      default: true
    },
    filtersVisible: {
      type: Boolean,
      default: true
    },
    restCoefic: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      enums: {
        FilterType
      },
      activeTab: 0,
      searchingSuggestions: null,
      conditions: Conditions as Record<FilterType, Filter>
    };
  },
  computed: {
    orderedSavedFilters(): SavedFilter[] {
      const filters = this.savedFilters as SavedFilter[];
      return filters.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    currentFilter() {
      const filters = this.savedFilters as SavedFilter[];
      return filters.find((f) => f.selected === true);
    },
    tabHeader(): string {
      if (this.currentFilter) {
        return this.currentFilter.name;
      }
      return 'New Filter';
    }
  },
  methods: {
    closeFilter() {
      this.$emit('closeFilter');
    },
    itemTooltip(item: string | number, filterKey: string, label: string): string {
      const selectedFilter = this.selectedConditions.find((filter) => filter.id === item);
      return selectedFilter?.selectedBox ? `${selectedFilter.selectedBox.name}: ${label}` : `${this.conditions[filterKey].name}: ${label}`;
    },
    selectOption(option, key) {
      this.conditions[key].options.forEach((opt) => {
        opt.selected = opt.id === option.id;
      });
    },
    dateSelect(filter) {
      const isRangeComplete = filter.date.every((d) => d);
      if (isRangeComplete) {
        const selectedOption = filter.options.find((option) => option.selected);

        this.$emit('update:date', {
          startDate: filter.date[0],
          endDate: filter.date[1],
          option: selectedOption
        });
      }
    },
    changeCheck(option, key) {
      const action = this.conditions[key].optionsSelected.includes(option.id) ? FilterAction.ADD : FilterAction.REMOVE;

      let title = this.conditions[key].name;

      this.$emit('update:checkbox', {
        id: key,
        option,
        title,
        action
      });
    },
    selectSavedFilter(filter) {
      this.$emit('update:saved', filter.filterId);
    },
    searchSuggestions(event, key) {
      let field: any = '';
      let selectedBox = {} as FilterItem;
      // Check if has options
      if (this.conditions[key].options) {
        selectedBox = this.conditions[key].options.find((option) => option.selected);
        field = selectedBox.id;
      } else {
        field = key;
      }
      this.searchingSuggestions = key;
      const fieldOptions = AutoCompleteEndpoints[field];

      this.$store.dispatch('filters/AUTOCOMPLETE', { field, search: event.query }).then((response) => {
        if (response && response.length > 0) {
          let suggestions = response.map((m) => {
            return {
              id: m[fieldOptions.filterAttribute],
              name: this.stripLeadingZeros(m[fieldOptions.attribute]),
              detail: this.getDetailString(fieldOptions, m, key)
            };
          });

          // clear existing items from results
          this.conditions[key].suggestions = suggestions.filter((f) => !this.conditions[key].optionsSelected.includes(f.id));
        } else {
          this.conditions[key].suggestions = [{ id: null, name: 'No results found', detail: null }];
        }

        this.searchingSuggestions = null;
      });
    },
    getDetailString(fieldOptions, m, key): string | null {
      if (fieldOptions.detail) {
        return fieldOptions.detailLabel + ': ' + this.stripLeadingZeros(m[fieldOptions.detail]);
      }
      if (key === FilterType.ADDITIONAL_FIELDS) {
        const selectedOpt = this.conditions[key].options.find((f) => f.selected === true);
        return selectedOpt.name;
      }
      return null;
    },
    stripLeadingZeros(val: string): string {
      const regex = /^0+/g;
      return val ? val.replaceAll(regex, '') : '';
    },
    selectSuggestion(item, key: string) {
      console.log('selectSuggestion called');
      if (item.value.id === null) {
        // if we are submitting a 'no result' label do nothing
        this.conditions[key].searchField = '';
        return;
      }
      this.conditions[key].searchField = '';

      let title = '';
      let selectedBox = {} as FilterItem;
      // Check if has options
      if (this.conditions[key].options) {
        selectedBox = this.conditions[key].options.find((option) => option.selected);
        title = selectedBox.name;
      } else {
        title = this.conditions[key].name;
      }

      this.$emit('update:suggestion', {
        id: key,
        option: item.value,
        title,
        ...(this.conditions[key].options && { selectedBox }),
        action: FilterAction.ADD
      });
    },
    removeSuggestion(item: string | number, key: string) {
      this.$emit('update:removeFilter', {
        id: item,
        filterId: key
      });
    },
    renameFilter(filter: SavedFilter, newName: string) {
      this.$emit('renameFilter', { filter, newName });
    },
    duplicateFilter(id: string) {
      this.$emit('duplicateFilter', id);
    },
    editFilter(filter: SavedFilter) {
      this.selectSavedFilter(filter);
      this.activeTab = 0;
    },
    promptRemoveSavedFilter(filter) {
      this.$store.commit('showDialogAlert', {
        text: `This will remove the filter '${filter.name}' permanently. Are you sure?`,
        header: 'Remove saved filter',
        type: 'confirm',
        actionYes: () => this.removeSavedFilter(filter.filterId),
        actionNo: () => this.$store.commit('hideDialogAlert')
      });
    },
    removeSavedFilter(id: string) {
      this.$emit('removeSavedFilter', id);
      this.$store.commit('hideDialogAlert');
    },

    // Updates the conditions component object to fill the filter form properly.
    refreshSelectedConditionsVM() {
      Object.keys(this.conditions).forEach((key) => {
        if (key === FilterType.TIME) {
          // specific for date condition
          // clear existing value
          if (this.conditions[FilterType.TIME].date?.length) {
            this.conditions[FilterType.TIME].date = [];
          }

          // add new item if exists
          const dateSelection = this.selectedConditions.find((f) => f.filterId === 'time');
          if (dateSelection) {
            const date = dateSelection.date;
            const startDateFormatted = new Date(date[0]);
            const endDateFormatted = new Date(date[1]);

            this.conditions[FilterType.TIME].date = [startDateFormatted, endDateFormatted];

            this.conditions[FilterType.TIME].options?.forEach((option) => {
              option.selected = option.id === dateSelection.id;
            });
          }
        } else {
          // for all other conditions
          this.conditions[key].optionsSelected = []; // clean previous items
          const selected = this.selectedConditions.filter((f) => f.filterId === key).map((m) => m.id);
          if (selected) {
            // add new item if exists
            this.conditions[key].optionsSelected.push(...selected);
          }
        }
      });
    }
  },
  watch: {
    selectedConditions: {
      handler() {
        this.refreshSelectedConditionsVM();
      },
      deep: true
    }
  },
  mounted() {
    this.refreshSelectedConditionsVM();
  }
});
