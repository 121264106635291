import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-151809d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "globalSearch" }
const _hoisted_2 = {
  key: 0,
  class: "detail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("em", {
      class: _normalizeClass(["b-icon", _ctx.searching ? 'miniSpinner' : 'search'])
    }, null, 2),
    _createVNode(_component_AutoComplete, {
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      class: "input-search wide",
      placeholder: "Search for shipments",
      field: "label",
      minLength: 2,
      delay: 500,
      forceSelection: "",
      dropdown: true,
      completeOnFocus: true,
      suggestions: _ctx.searchResults,
      onComplete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fetchSearchResults($event.query))),
      onItemSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchSearch($event)))
    }, {
      item: _withCtx((slotProps) => [
        _createElementVNode("div", {
          class: _normalizeClass({ noResults: !_ctx.toSearchResult(slotProps).item.id })
        }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.toSearchResult(slotProps).item.label ? _ctx.toSearchResult(slotProps).item.label : _ctx.toSearchResult(slotProps).item.id), 1),
          (_ctx.toSearchResult(slotProps).item.fieldName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.getTypeTag(_ctx.toSearchResult(slotProps).item.fieldName)), 1))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["modelValue", "suggestions"])
  ]))
}