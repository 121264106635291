import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d985f27"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "b-icon add" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.isArray)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "field",
        style: _normalizeStyle(`flex: 0 0 ${_ctx.width}px;`)
      }, [
        _withDirectives(_createElementVNode("button", _hoisted_1, null, 512), [
          [
            _directive_tooltip,
            {
          value: _ctx.getItemsList(_ctx.value),
          class: 'custom-tooltip'
        },
            void 0,
            { top: true }
          ]
        ]),
        _createTextVNode(" " + _toDisplayString(_ctx.value), 1)
      ], 4))
    : (_ctx.isEditable && _ctx.isEditing)
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: "text",
          ref: "input",
          onKeypress: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleChangeEvent && _ctx.handleChangeEvent(...args)), ["enter"])),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeEvent && _ctx.handleChangeEvent(...args))),
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
          class: "field",
          style: _normalizeStyle(`flex: 0 0 ${_ctx.width}px;`)
        }, null, 36))
      : (_ctx.isEditable && !_ctx.isEditing)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            onClick: [
              _cache[3] || (_cache[3] = ($event: any) => (_ctx.setEditing())),
              _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
            ],
            class: _normalizeClass(["field field--editable", { 'field--edited': _ctx.isEdited }]),
            style: _normalizeStyle(`flex: 0 0 ${_ctx.width}px;`)
          }, _toDisplayString(_ctx.value), 7))
        : (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "field",
            style: _normalizeStyle(`flex: 0 0 ${_ctx.width}px;`)
          }, _toDisplayString(_ctx.value), 5))
}