
import { defineComponent } from 'vue';
import { globalDateTimeFormat } from '@/constants';
import DetailLabel from '@/components/main/DetailLabel.vue';
import TimelineTable from '@/components/main/TimelineTable.vue';
import TransportPartner from './TransportPartner.vue';

export default defineComponent({
  name: 'TimelineSegment',
  components: {
    DetailLabel,
    TimelineTable,
    TransportPartner
  },
  props: {
    segments: {
      type: Object,
      default: () => ({})
    },
    item: {
      type: Object,
      required: true
    },
    sidx: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      globalDateTimeFormat: globalDateTimeFormat,
      showDetails: false
    };
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails; // Update the local data property instead of mutating the prop
    },
    isIntermediate(item) {
      return item.segmentClassifier == 'INTERMEDIATE';
    }
  }
});
