
import { defineComponent, PropType } from 'vue';
import Paginator from 'primevue/paginator';
import Dropdown from 'primevue/dropdown';
import { TableColumn } from '@/typings';
import { CarrierStatus, tableColumnsHeaders } from '@/typings/enums';
import { NonSortableColumns } from '@/constants/tableColumns';
import Spinner from '@/components/common/BasfSpinner.vue';
import BasfSwitch from '@/components/common/BasfSwitch.vue';
import TableCell from '@/components/main/TableCell.vue';
import Filters from '@/scripts/componentFilter';

export default defineComponent({
  name: 'DeliveriesTable',
  components: { Paginator, Dropdown, BasfSwitch, Spinner, TableCell },
  props: {
    data: {
      type: Array as any,
      required: true
    },
    columns: {
      type: Array as PropType<Array<TableColumn>>,
      required: true
    },
    initialHScroll: {
      type: Number,
      default: 0
    },
    restCoefic: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      rowsOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      nonSortableColumns: NonSortableColumns,
      positions: {
        clientX: 0,
        clientY: 0,
        movementX: 0,
        movementY: 0
      },
      activeColumn: '',
      selectedRow: {} as string | null,
      enums: {
        CarrierStatus
      },
      patchTop: 0,
      unsubscribeCloseDetail: null as any,
      columnsHeaders: tableColumnsHeaders,
      editing: null as { row: string; col: string } | null
    };
  },
  computed: {
    currentTimezone(): string {
      let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (this.$store.state.userData.settings.userLocale && this.$store.state.userData.settings.userLocale.timezone) {
        tz = this.$store.state.userData.settings.userLocale.timezone.name;
      }
      return tz;
    },
    showDetails(): boolean {
      return this.$store.state.showDetails;
    },
    showFilters(): boolean {
      return this.$store.state.showFilters;
    },
    totalCount(): number {
      return this.$store.state.shipments.totalCountForFilter;
    },
    dqTeamAuthorized(): boolean {
      return this.$store.getters.isDQTeamAuthorized;
    },
    pageSize: {
      get(): number {
        return this.$store.state.shipments.pageSize;
      },
      set(val: number) {
        this.$emit('pageSize', val);
      }
    },
    contentWidth(): number {
      const iconWidth = 35;
      return this.columns.reduce((ps, r) => ps + r.width, 0) + iconWidth;
    },
    followedDeliveries(): any[] {
      return this.$store.state.userData ? this.$store.state.userData.followedDeliveries : [];
    },
    page(): number {
      return this.$store.state.shipments.page;
    },
    sort(): any {
      return this.$store.state.shipments.sort;
    },
    fetching(): boolean {
      return this.$store.state.shipments.fetchingShipments;
    }
  },
  methods: {
    isArray(col, row): boolean {
      let result = this.extractDataValue(col, row);
      if (Array.isArray(result)) {
        if (result.length > 1) {
          return true;
        }
        return false;
      }
      return false;
    },
    getTableValue(col, row): string | string[] {
      let result = this.extractDataValue(col, row);
      if (Array.isArray(result)) {
        if (result.length > 1) {
          return result;
        }
        return result[0];
      }
      return result;
    },
    extractDataValue(col, row): string | string[] {
      const property = col.property ? col.property : col.field;
      const noResult = '-';
      if (col.path) {
        return col.path(row[property], this.dqTeamAuthorized);
      } else {
        return row[property] ? row[property] : noResult;
      }
    },
    isEditable(col: TableColumn): boolean {
      return col.dqTeamEdit ?? false ? this.dqTeamAuthorized : false;
    },
    isEdited(col: TableColumn, shipment: any): boolean {
      return this.$store.getters['shipments/isEdited'](shipment.deliveryNumber, col);
    },
    setTableValue(col: TableColumn, shipment: any, value: string) {
      if (this.isEditable(col)) {
        if (col.dqTeamEdit?.isValid(value)) {
          this.$store.commit('shipments/editDelivery', { deliveryNumber: shipment.deliveryNumber, col: col, value: value });
        }
      }
    },
    toggleShipmentAlert(shipment, value: boolean) {
      // update DB
      this.$store.dispatch('shipments/FOLLOW_SHIPMENTS', { followedDelivery: { logisticTrackerId: shipment.id }, status: value });
    },
    async showDetailRow(row) {
      if (!this.editing) {
        this.$store.dispatch('SET_SHOW_FILTERS', false);

        if (this.selectedRow === row.deliveryNumber) {
          this.$store.dispatch('SET_SHOW_DETAILS', false);

          this.selectedRow = null;
          this.$emit('selectRow', null);
          return;
        }
        this.selectedRow = row.deliveryNumber;
        this.$emit('selectRow', this.selectedRow);

        await this.$nextTick();
        this.handleTableItemsPosition();

        this.$store.dispatch('SET_SHOW_DETAILS', true);
      }
    },
    handleSort(column: string | null) {
      let newOrder: string | null = 'ASC';
      if (this.sort.field === column) {
        if (this.sort.order === 'ASC') {
          newOrder = 'DESC';
        }
        if (this.sort.order === 'DESC') {
          newOrder = null;
          column = null;
        }
      }
      this.$emit('sort', { field: column, order: newOrder });
    },
    getSortIcon(field: string) {
      if (this.sort.field === field) {
        return this.sort.order === 'ASC' ? 'b-icon sort-asc' : 'b-icon sort-desc';
      }
      return 'b-icon sort';
    },
    dragMouseDown(event: MouseEvent, colId: string) {
      event.preventDefault();
      this.activeColumn = colId;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event: MouseEvent) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      this.$store.commit('changeColumnSize', {
        column: this.activeColumn,
        displacement: this.positions.movementX
      });
    },
    closeDragElement() {
      this.$emit('dragEnded');
      document.onmouseup = null;
      document.onmousemove = null;
    },
    setPage(e) {
      this.$emit('page', e.page);
    },
    iconStatus(lateness): string {
      //null scenarios
      if (!lateness) {
        return '';
      } else if (!lateness.status) {
        if (lateness.amount > 0) {
          lateness.status = 'LATE';
        } else {
          lateness.status = 'EARLY';
        }
      }

      let statusClass = '';
      if (lateness.status === 'ON_TIME') {
        statusClass = 'check_circle icon-status--inTime';
      } else if (lateness.status === 'EARLY') {
        statusClass = 'check_circle icon-status--early';
      } else if (lateness.status === 'LATE') {
        statusClass = 'attention icon-status--delay';
      }

      return statusClass;
    },

    setInitialScroll() {
      (this.$refs.tableHeader as HTMLElement).scrollLeft = this.initialHScroll;
      (this.$refs.tableContent as HTMLElement).scrollLeft = this.initialHScroll;
    },
    handleTableItemsPosition() {
      // push the header horizontally when scroll
      (this.$refs.tableHeader as HTMLElement).scrollLeft = (this.$refs.tableContent as HTMLElement).scrollLeft;

      // put the patch on the right position vertically
      const tableContent = this.$refs.tableContent as HTMLElement;
      const selectedRow = this.$el.querySelector('.row.open');
      if (selectedRow) {
        const offsetTop = selectedRow.offsetTop;
        const scrollTop = tableContent.scrollTop;
        const tableHeader = 109; // value to fix patch position
        this.patchTop = offsetTop - scrollTop + tableHeader;
      } else {
        // giving a negative position, makes the patch not to be visible, it's a fallback scenario.
        this.patchTop = -30;
      }

      this.$emit('updatePatch', this.patchTop);
    }
  },
  mounted() {
    this.handleTableItemsPosition();

    this.unsubscribeCloseDetail = this.$store.subscribeAction((action) => {
      // If close details dialog clear selectedRow
      if (action.type === 'SET_SHOW_DETAILS' && !action.payload) {
        this.selectedRow = null;
      }
    });
  },
  beforeUnmount() {
    this.unsubscribeCloseDetail();
  },
  watch: {
    showFilters(val: boolean) {
      // Unselect the selected row if the filter is open
      if (val) {
        this.selectedRow = null;
      }
    },
    async fetching(val: boolean) {
      if (val === false) {
        await this.$nextTick();
        this.setInitialScroll();
      }
    }
  }
});
