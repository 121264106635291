
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'TableCell',
  props: {
    isArray: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String, Array] as PropType<string | string[]>,
      required: false
    },
    isEdited: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isEditing: false
    };
  },
  methods: {
    setEditing() {
      if (this.isEditable) {
        this.isEditing = true;
        // input element will be rendered due to change above.
        // focus it afterwards
        this.$nextTick(function () {
          (this.$refs.input as any).focus();
        });
      }
    },
    setNotEditing() {
      this.isEditing = false;
    },
    handleChangeEvent(event: Event) {
      this.setValue((event?.target as HTMLInputElement)?.value);
    },
    setValue(newValue) {
      this.$emit('changeValue', newValue);
      this.setNotEditing();
    },
    getItemsList(items) {
      return items.join('</br>');
    }
  }
});
