import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d96170ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "detail-dialog",
  ref: "detailDialog"
}
const _hoisted_2 = { class: "detail-dialog__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RowDetailTimeline = _resolveComponent("RowDetailTimeline")!
  const _component_RowDetailInfo = _resolveComponent("RowDetailInfo")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.dataLoaded)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_RowDetailTimeline, {
              class: "detail-dialog__wrapper__content",
              loaded: _ctx.timelineLoaded,
              data: _ctx.data.timeline
            }, null, 8, ["loaded", "data"]),
            _createVNode(_component_RowDetailInfo, {
              class: "detail-dialog__wrapper__content",
              data: _ctx.data.masterData
            }, null, 8, ["data"])
          ], 64))
        : (_openBlock(), _createBlock(_component_Spinner, { key: 1 }))
    ]),
    _createElementVNode("div", {
      class: "patch",
      style: _normalizeStyle(`top:${_ctx.patchTop}px`)
    }, null, 4)
  ], 512))
}