
import { defineComponent } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import RowDetailInfoItemData from '@/components/main/RowDetailInfoItemData.vue';
import RowDetailInfoContactData from '@/components/main/RowDetailInfoContactData.vue';
import RowDetailInfoMasterData from '@/components/main/RowDetailInfoMasterData.vue';
import { DeliveryDetail } from '@/services/model/vista';
import { PropType } from 'vue';

export default defineComponent({
  name: 'RowDetailInfo',
  components: {
    TabView,
    TabPanel,
    RowDetailInfoItemData,
    RowDetailInfoContactData,
    RowDetailInfoMasterData
  },
  props: {
    data: {
      type: Object as PropType<DeliveryDetail>,
      required: false
    }
  },
  data() {
    return {
      accordionNodes: [
        { id: 'purchaseOrderPartners', label: 'Purchase Order', open: true },
        { id: 'salesOrderPartners', label: 'Sales Order', open: true },
        { id: 'deliveryPartners', label: 'Delivery', open: true },
        { id: 'prelegPartners', label: 'Shipment Preleg', open: true },
        { id: 'mainOrDirectlegPartners', label: 'Shipment Mainleg', open: true }
      ]
    };
  },
  methods: {
    closeDialog() {
      this.$store.dispatch('SET_SHOW_DETAILS', false);
    },
    toogleAccordion(id) {
      const element = this.accordionNodes.find((f) => f.id === id);
      element!.open = !element?.open;
    }
  }
});
