
import { defineComponent } from 'vue';
import FiltersUtils from '@/scripts/filtersUtils';
import { FilterAdditional } from '@/typings/enums';

export default defineComponent({
  name: 'DownloadExcel',
  components: {},
  data() {
    return {
      isSaving: false,
      show: true
    };
  },
  props: {
    withAlert: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Object,
      required: true
    }
  },
  computed: {
    totalItems(): number {
      return this.$store.state.shipments.totalCountForFilter;
    }
  },
  methods: {
    async downloadXLS() {
      this.isSaving = true;

      // prepare params object to send to api
      let toCheckConditions = FiltersUtils.createSaveObject(this.$store.state.filters.selectedConditions);

      if (this.withAlert) {
        const followedDeliveries = this.$store.state.userData.followedDeliveries.map((m) => {
          return { searchTerm: m, label: null };
        });

        toCheckConditions.push({
          fieldName: FilterAdditional.deliverID,
          value: followedDeliveries
        });
      }

      const data = {
        conditions: toCheckConditions,
        exportColumns: this.columns.map((m) => m.field)
      };
      await this.$store.dispatch('shipments/EXPORT_CSV', data);

      this.$emit('close');
    }
  },
  watch: {
    show() {
      this.$emit('close');
    }
  }
});
