import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00e076f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "segment__spacer"
}
const _hoisted_2 = { class: "main-segment__box" }
const _hoisted_3 = { class: "main-segment__details" }
const _hoisted_4 = { class: "main-segment__title" }
const _hoisted_5 = { class: "main-segment__subtitle" }
const _hoisted_6 = { class: "main-segment__dates" }
const _hoisted_7 = {
  key: 0,
  class: "subtimeline"
}
const _hoisted_8 = {
  key: 0,
  class: "subtimeline__wrapper"
}
const _hoisted_9 = {
  key: 0,
  class: "intermediate-segment__box"
}
const _hoisted_10 = {
  key: 2,
  class: "intermediate-segment__box"
}
const _hoisted_11 = {
  key: 0,
  class: "subtimeline"
}
const _hoisted_12 = {
  key: 0,
  class: "subtimeline__wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailLabel = _resolveComponent("DetailLabel")!
  const _component_timeline_table = _resolveComponent("timeline-table")!
  const _component_transport_partner = _resolveComponent("transport-partner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.sidx <= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.isIntermediate(_ctx.item))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.point?.name ?? 'Unknown'), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString((_ctx.item.point?.city ?? 'Unknown') + ', ' + (_ctx.item.point?.country ?? 'Unknown')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                (_ctx.item.estimatedArrival)
                  ? (_openBlock(), _createBlock(_component_DetailLabel, {
                      key: 0,
                      type: "ESTIMATED",
                      depArrival: "ARRIVAL",
                      data: _ctx.item.estimatedArrival
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true),
                (_ctx.item.actualArrival)
                  ? (_openBlock(), _createBlock(_component_DetailLabel, {
                      key: 1,
                      type: "ACTUAL",
                      depArrival: "ARRIVAL",
                      data: _ctx.item.actualArrival
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                (_ctx.item.estimatedDeparture)
                  ? (_openBlock(), _createBlock(_component_DetailLabel, {
                      key: 0,
                      type: "ESTIMATED",
                      depArrival: "DEPARTURE",
                      data: _ctx.item.estimatedDeparture
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true),
                (_ctx.item.actualDeparture)
                  ? (_openBlock(), _createBlock(_component_DetailLabel, {
                      key: 1,
                      type: "ACTUAL",
                      depArrival: "DEPARTURE",
                      data: _ctx.item.actualDeparture
                    }, null, 8, ["data"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.item.subTimeline && _ctx.item.subTimeline.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_ctx.showDetails)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_timeline_table, { item: _ctx.item }, null, 8, ["item"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDetails && _ctx.toggleDetails(...args))),
                    class: "subtimeline__trigger"
                  }, _toDisplayString(_ctx.showDetails ? 'See less' : 'See more'), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.sidx < _ctx.segments.length - 1 && !_ctx.isIntermediate(_ctx.segments[_ctx.sidx + 1]))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_transport_partner, {
                  segment: _ctx.segments[_ctx.sidx + 1]
                }, null, 8, ["segment"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_transport_partner, {
            segment: _ctx.segments[_ctx.sidx + 1]
          }, null, 8, ["segment"]),
          (_ctx.isIntermediate(_ctx.item))
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_ctx.showDetails && _ctx.item.subTimeline && _ctx.item.subTimeline.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_timeline_table, { item: _ctx.item }, null, 8, ["item"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDetails && _ctx.toggleDetails(...args))),
                  class: "subtimeline__trigger"
                }, _toDisplayString(_ctx.showDetails ? 'See less' : 'See more'), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}