
import { defineComponent } from 'vue';
import ContextMenu from 'primevue/contextmenu';
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
  name: 'RowDetailInfo',
  components: {
    ContextMenu
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newName: this.filter.name,
      renaming: false,
      filtersMenu: [
        {
          label: 'Edit',
          command: () => {
            this.$emit('editFilter');
          }
        },
        {
          label: 'Duplicate',
          command: () => {
            this.$emit('duplicateFilter', this.filter.filterId);
          }
        },
        {
          label: 'Rename',
          command: () => {
            this.openRenameFilter();
          }
        },
        {
          label: 'Delete',
          command: () => {
            this.$emit('deleteFilter');
          }
        }
      ]
    };
  },
  methods: {
    closeContext() {
      (this.$refs as any).filtersContextMenu.hide();
    },
    handleContext(event) {
      (this.$refs as any).filtersContextMenu.show(event);
    },
    async openRenameFilter() {
      this.renaming = true;
      await this.$nextTick();
      (this.$refs as any).nameField.$el.focus();
    },
    renameFilter() {
      this.$emit('renameFilter', this.newName);
      this.renaming = false;
    }
  },
  mounted() {
    onClickOutside(this.$refs.filtersContextMenu as any, (event) => this.closeContext());
  }
});
