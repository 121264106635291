import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46839bc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-wrapper" }
const _hoisted_2 = { class: "header-close" }
const _hoisted_3 = {
  key: 0,
  class: "info-tabs"
}
const _hoisted_4 = { class: "tab-wrapper" }
const _hoisted_5 = { class: "tab-wrapper" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RowDetailInfoMasterData = _resolveComponent("RowDetailInfoMasterData")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_RowDetailInfoItemData = _resolveComponent("RowDetailInfoItemData")!
  const _component_RowDetailInfoContactData = _resolveComponent("RowDetailInfoContactData")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "p-button-text b-icon close",
        onClick: _ctx.closeDialog
      }, null, 8, ["onClick"])
    ]),
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_TabView, null, {
            default: _withCtx(() => [
              (_ctx.data)
                ? (_openBlock(), _createBlock(_component_TabPanel, {
                    key: 0,
                    header: "Master data"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_RowDetailInfoMasterData, { "delivery-detail": _ctx.data }, null, 8, ["delivery-detail"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_TabPanel, { header: "Item data" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.lineItems, (item, index) => {
                      return (_openBlock(), _createBlock(_component_RowDetailInfoItemData, {
                        key: 'item_' + index,
                        "item-data": item,
                        "primary-mode": _ctx.data.primaryMode
                      }, null, 8, ["item-data", "primary-mode"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanel, { header: "Contact data" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accordionNodes, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["accordion", { disabled: !_ctx.data[item.id] || !_ctx.data[item.id].length }]),
                        key: item.id
                      }, [
                        _createElementVNode("button", {
                          class: "accordion__tab",
                          onClick: ($event: any) => (_ctx.toogleAccordion(item.id))
                        }, [
                          _createElementVNode("span", null, _toDisplayString(item.label), 1),
                          _createElementVNode("span", {
                            class: _normalizeClass(["b-icon", !item.open || !_ctx.data[item.id] || !_ctx.data[item.id].length ? 'arrow_down' : 'arrow_up'])
                          }, null, 2)
                        ], 8, _hoisted_6),
                        (_ctx.data[item.id])
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["accordion__content", item.open ? 'open' : ''])
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data[item.id], (element, index) => {
                                return (_openBlock(), _createBlock(_component_RowDetailInfoContactData, {
                                  key: 'item_' + index,
                                  "item-data": element
                                }, null, 8, ["item-data"]))
                              }), 128))
                            ], 2))
                          : _createCommentVNode("", true)
                      ], 2))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}